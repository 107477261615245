import './About.css';
import cinema from './images/cinema.jpg'

export default function About(){
    return(
        <div className='about'>
            <p>Welcome to Tongariro Cinemas, where the magic of movies comes to life! We are thrilled to introduce you to a world of captivating stories, larger-than-life visuals, and immersive experiences that will transport you to new dimensions. As a premier movie destination in the heart of the Newtown, we strive to provide an exceptional cinematic journey for both local residents and visiting tourists.<br/>At Tongariro Cinemas, we understand the power of cinema to inspire, entertain, and bring people together. Our state-of-the-art theater offers a cutting-edge audiovisual experience that will leave you mesmerized. From the moment you step through our doors, you will be enveloped in an atmosphere of comfort, excitement, and anticipation.<br/>Whether you're a fan of the latest blockbuster hits, indie gems, or timeless classics, our diverse selection of films ensures there's something for everyone. We curate a wide range of genres, including action, romance, comedy, drama, sci-fi, and more, catering to various tastes and preferences. From Holywood blockbusters to thought-provoking independent films, we strive to provide a rich and diverse cinematic experience that appeals to all movie enthusiasts.</p>
            <img src={cinema} alt=''></img>
            <p>Our state-of-the-art screening rooms are equipped with top-of-the-line projection systems and immersive surround sound, delivering a visual and auditory feast that enhances your movie-watching experience. Our comfortable seating arrangements are designed to provide ample legroom and unobstructed views, ensuring that every seat is the best seat in the house.<br/>At Tongariro Cinemas, we believe that the cinema experience extends beyond just watching a movie. That's why we offer a range of amenities to make your visit even more enjoyable. Indulge in a variety of delectable snacks and refreshing beverages from our concession stands, elevating your movie experience with tasty treats. We also host special screenings, themed events, and exclusive premieres to add an extra touch of excitement and exclusivity.<br/>We are proud to be a part of the Newtown community and are committed to creating a space where people can come together, connect, and share the joy of cinema. Our friendly and knowledgeable staff is dedicated to providing exceptional customer service, ensuring that your visit to Tongariro Cinemas is a memorable one.<br/>Join us as we embark on a cinematic journey that will transport you to captivating worlds, stir your emotions, and ignite your imagination. We invite you to experience the magic of movies at Tongariro Cinemas, where every frame tells a story, and every visit is an unforgettable adventure. Welcome to a world of cinematic wonder!</p>
        </div>

    );
}