import './Purchase.css';
import {React, useState} from 'react';

export default function Purchase(){
    const[price, setPrice] = useState(0)
    const[adult, setAdult] = useState(0)
    const[child, setChild] = useState(0)
    const[student, setStudent] = useState(0)
    
    const addAdult = () => {
        setAdult(adult => adult+1);
    }

    const takeAdult = () => {
        if (adult>0){
        setAdult(adult => adult-1);}
    }

    const addChild = () => {
        setChild(child => child+1);
    }

    const takeChild = () => {
        if (child>0){
        setChild(child => child-1);}
    }

    const addStudent = () => {
        setStudent(student => student+1);
    }

    const takeStudent = () => {
        if (student>0){
        setStudent(student => student-1);}
    }

    const calc = () => {
        setPrice(price=>adult*24.5+child*18.5+student*21.5);
    }
   
    return(
        <div className='back'>
            <div className='main2'>
                
                <div>
                    <label>Adults $23</label>
                    <span>{adult}</span>
                    <button onClick={addAdult}>+1</button>
                    <button onClick={takeAdult}>-1</button>
                </div>
                <div>
                    <label>Children $17</label>
                    <span>{child}</span>
                    <button onClick={addChild}>+1</button>
                    <button onClick={takeChild}>-1</button>
                </div>
                <div>
                    <label>Student $20</label>
                    <span>{student}</span>
                    <button onClick={addStudent}>+1</button>
                    <button onClick={takeStudent}>-1</button>
                </div>
                <div><label>Popcorn</label></div>
                <div className='corn'>
                    <button onClick={() =>{setPrice(price => price+15)}}>LARGE</button>
                    <button onClick={() =>{setPrice(price => price+12)}}>Regular</button>
                    <button onClick={() =>{setPrice(price => price+9)}}>small</button>
                </div>
                <div>
                    <button onClick={() => {setPrice(price=>0)}}>Reset</button>
                    <button onClick={calc}>Calculate</button>
                    <span id='price'>{price}NZD</span>
                </div>
                <div>
                    <h6>For every ticket you buy, there is a $1.5 booking fee</h6>
                </div>
            </div>
        </div>
    );
}