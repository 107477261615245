import './Contact.css';

export default function Contact(){
    return(
        <form className='message' method='post'>
            <section className='nameemail'>
                <div className='name'>
                    <label >Name</label>
                    <input></input>
                </div>
                <div className='email'>
                    <label>Email</label>
                    <input></input>
                </div>
            </section>
            <section>
                <div className='mesage'>
                    <label>Message</label>
                    <textarea className='messageInput'></textarea>
                </div>
            </section>
            <button>Submit</button>
        </form>
    );
}