//This is the header

import './App.css';

function App() {
  return (
    <div className="App">
      <div className="Header">
        <a href='home' className='logo'>Tongariro<br/>Cinemas</a>
        <nav>
          <ul>
            <li><a href='home'>Home</a></li>
            <li><a href="contact">Contact</a></li>
            <li><a href="about">About</a></li>
            <li><a href="login">Login</a></li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default App;
