import "./home_slide.css";
import elem from "./images/Banner-Home-Elemental-AAK.png";
import ind1 from "./images/Banner-Home-Indiana5-ICN.jpg";
import ind2 from "./images/Indiana_Jones_Quiz_Homepage_Banner.jpg";
import phot from "./images/Banner-Home-Last-Rider-WIN.jpg"

function Slide(){
    return(
    <div className="main">
      <ul>
        <li><a href="book"><img src={ind2} alt=""></img></a></li>
        <li><a href="book"><img src={ind1} alt="dd"></img></a></li>
        <li><a href="book"><img src={elem} alt="elementals"></img></a></li>
        <li><a href="book"><img src={phot} alt=""></img></a></li>
      </ul>
      
    </div>
    )
}

export default Slide;