import './Footer.css';

function Footer() {
  return (
    <div>
      <div className="Footer">
        <a href='home' className='logo2'>Tongariro<br/>Cinemas</a>
        <nav>
          <ul>
            <li><a href='home'>Home</a></li>
            <li><a href="contact">Contact</a></li>
            <li><a href="about">About</a></li>
            <li><a href="login">Login</a></li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Footer;
