import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Slide from './home_slide'
import Book from './Book';
import Footer from './Footer';
import Login from './Login';
import { BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import Contact from './Contact';
import About from './About';
import Purchase from './Purchase';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App />
      <Switch>
        <Route path='/home'>
          <Slide />
          <Book />
        </Route>
        <Route path='/login'>
          <Login />
        </Route>
        <Route path='/contact'>
          <Contact />
        </Route>
        <Route path='/about'>
          <About />
        </Route>
        <Route path='/purchase'>
          <Purchase />
        </Route>
      </Switch>
      <Footer />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
