import './Login.css';

export default function Login() {
    return(
        <div className='login-items'>
            <h1>LOGIN</h1>
            <div className='labels-top'>
                <label>Email</label>
                <input placeholder='12345@gmail.com'></input>
            </div>
            <div className='labels'>
                <label>Password</label>
                <input type="password"></input>
            </div>
            <button type="submit">Login</button>
        </div>
    );
}