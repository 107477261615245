import './Book.css';
import {React, useState} from 'react';

function Book(props){
    const[name, setName] = useState('FILM');

    const doit1 = () => {
        setName('Indiana Jones Quiz of Destiny');
    }
    const doit2= () =>{
        setName('Indiana Jones Dial of Destiny');
    }
    const doit3= () =>{
        setName('Elementals');
    }
    const doit4= () =>{
        setName('The Last Rider');
    }

    return (
        <div className="book">
            <div className='title'>BOOK A FILM</div>
            <div>
                <div className='choice'>
                    <span>{name}</span>
                </div>
                <table className='movies'>
                    <tr><td onClick={doit1}>Indiana Jones Quiz of Destiny</td></tr>
                    <tr><td onClick={doit2}>Indiana Jones Dial of Destiny</td></tr>
                    <tr><td onClick={doit3}>Elementals</td></tr>
                    <tr><td onClick={doit4}>The Last Rider</td></tr>
                </table>
            </div>
            <a href='purchase' className='bookbtn'>Book</a>
        </div>

    )
}

export default Book;